import { changelogMenu } from 'frontend-container/components/Menu/configuration/multiContextModules/changelog';
import { accountsReceivableMenu } from 'frontend-container/components/Menu/configuration/propertyModules/accountsReceivable';
import { ACTIVITY_RESERVATIONS_MENU_ID } from 'frontend-container/components/Menu/configuration/propertyModules/activityReservations';
import { cashieringMenu } from 'frontend-container/components/Menu/configuration/propertyModules/cashiering/cashiering';
import { endOfDayMenu } from 'frontend-container/components/Menu/configuration/propertyModules/cashiering/endOfDay';
import { GOLF_MENU_ID } from 'frontend-container/components/Menu/configuration/propertyModules/golf';
import { housekeepingMenu } from 'frontend-container/components/Menu/configuration/propertyModules/housekeeping';
import { meetingsAndEventsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/meetingsAndEvents';
import { profilesMenu } from 'frontend-container/components/Menu/configuration/propertyModules/profiles';
import { propertyUsers } from 'frontend-container/components/Menu/configuration/propertyModules/propertyUsers';
import { rateManagerMenu } from 'frontend-container/components/Menu/configuration/propertyModules/rateManager';
import { reportsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/reports';
import { frontDeskMenu } from 'frontend-container/components/Menu/configuration/propertyModules/reservations/frontdesk';
import { inquiryMenu } from 'frontend-container/components/Menu/configuration/propertyModules/reservations/inquiry';
import { reservationsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/reservations/reservations';
import { GROUPS_MENU_ID } from 'frontend-container/components/Menu/configuration/propertyModules/reservationsGroups';
import { resourceManagementMenu } from 'frontend-container/components/Menu/configuration/propertyModules/resourceManagement';
import { salesActivitiesMenu } from 'frontend-container/components/Menu/configuration/propertyModules/taskManagement/salesActivities';
import { taskManagementMenu } from 'frontend-container/components/Menu/configuration/propertyModules/taskManagement/taskManagement';
import { travelAgentCommissionsMenu } from 'frontend-container/components/Menu/configuration/propertyModules/travelAgentComissions';
import {
  customersMenuFull,
  customersMenuReadOnlyMode,
} from 'frontend-container/components/Menu/configuration/tenantModules/configuration/systemUser/customers';
import { SYSTEM_USERS_MENU_ID } from 'frontend-container/components/Menu/configuration/tenantModules/configuration/systemUser/systemUsers';
import { integrationsMenu } from 'frontend-container/components/Menu/configuration/tenantModules/integrations';
import { MENU_LINKS_ID } from 'frontend-container/components/Menu/constants';

export const MEGA_MENU_LEFT_ORDER: string[][] = [
  [inquiryMenu.id, reservationsMenu.id],
  [frontDeskMenu.id],
  [cashieringMenu.id],
  [profilesMenu.id],
  [meetingsAndEventsMenu.id],
  [GROUPS_MENU_ID],
  [ACTIVITY_RESERVATIONS_MENU_ID],
  [GOLF_MENU_ID],
  [housekeepingMenu.id],
  [rateManagerMenu.id],
  [reportsMenu.id],
  [
    taskManagementMenu.id,
    salesActivitiesMenu.id,
    propertyUsers.id,
    resourceManagementMenu.id,
  ],
  [travelAgentCommissionsMenu.id, accountsReceivableMenu.id],
  [integrationsMenu.id],
  [changelogMenu.id],
  [endOfDayMenu.id],
  [MENU_LINKS_ID],
  [customersMenuFull.id],
  [customersMenuReadOnlyMode.id],
  [SYSTEM_USERS_MENU_ID],
];
