import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { breadcrumbsUnitItemId } from 'frontend-container/components/Menu/components/Breadcrumbs/Breadcrumbs';
import { DateTimeHook } from 'frontend-container/components/Menu/components/Context';
import { GenericList } from 'frontend-container/components/Menu/components/Context/components/GenericList';
import {
  mapContextsToOptions,
  mapMenuItemsToOptions,
  mapTabsToOptions,
  TabOption,
} from 'frontend-container/components/Menu/components/Context/mapListsToOptions';
import {
  ButtonContextOption,
  ContextOption,
} from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { useContextsContext } from 'frontend-container/components/Menu/store/context';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';
import { isContextList } from 'frontend-container/components/Menu/utils/listOnChangeHandler';
import { useRouterContext } from 'frontend-container/components/Router/context/context';

import { isDefined } from '@ac/library-utils/dist/utils';
import {
  AcSelectOption,
  AcSelectValue,
  AlignItems,
  Color,
  FlexDirection,
  TextColor,
} from '@ac/web-components';

import { ContextDropdown } from './components/ContextDropdown';
import { ContextHeader } from './components/ContextHeader';
import { Context, ContextType } from './context';

interface Props {
  selectedContext: Context | undefined;
  onSelect: (
    selected: Context | Array<AcSelectOption<AcSelectValue>>,
    discardUnsavedChanges?: boolean,
    tabId?: string
  ) => void;
  useDateTime: DateTimeHook;
  dateLabel: string;
  timeLabel: string;
  color?: string | Color | TextColor;
  thresholdToShowTabs?: number;
  isContextSwitchHidden?: boolean;
  triggerId?: string;
  shouldActiveConfiguration?: boolean;
  distanceFromTarget?: number;
}

const getContextList = (
  list: ButtonContextOption | Context[] | undefined,
  selectedContext: Context | undefined,
  tabsOptions: ButtonContextOption[]
): TabOption[] => {
  return isContextList(list)
    ? mapContextsToOptions(list)
    : [
        ...mapMenuItemsToOptions(list, selectedContext),
        ...mapTabsToOptions(tabsOptions),
      ];
};

export const ContextComponent = ({
  selectedContext,
  onSelect,
  useDateTime,
  dateLabel,
  timeLabel,
  color,
  thresholdToShowTabs = 1,
  isContextSwitchHidden = false,
  triggerId,
  shouldActiveConfiguration,
  distanceFromTarget,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [isListVisible, setIsListVisible] = useState<boolean>(
    isContextSwitchHidden
  );
  const selectedCro = useRouterContext((store) => store.selectedCro);
  const selectedProperty = useRouterContext((store) => store.selectedProperty);
  const selectedProfileCenter = useRouterContext(
    (store) => store.selectedProfileCenter
  );

  const selectedUnits = useMemo(
    () =>
      [selectedCro?.id, selectedProperty?.id, selectedProfileCenter?.id].filter(
        isDefined
      ),
    [selectedCro?.id, selectedProperty?.id, selectedProfileCenter?.id]
  );

  const { allContextLists, tabsOptions, currentButtonOption } =
    useContextsContext();

  const [activeTab, setActiveTab] = useState(
    currentButtonOption?.id || ContextOption.property
  );

  const { date, dateFormat, time } = useDateTime();

  const handleSetActiveTab = (newActiveTab: string): void => {
    setActiveTab(newActiveTab);
  };

  const openList = (): void => setIsListVisible(true);

  const closeList = (): void => setIsListVisible(false);

  const handleSelect = (context: AcSelectOption[] | Context): void => {
    onSelect(context, false, activeTab || '');
    closeList();
  };

  const isMenuV2EnabledCached = getIsMenuV2Enabled();

  const showTabs =
    (tabsOptions && tabsOptions?.length > thresholdToShowTabs) || false;

  useEffect(() => {
    if (
      selectedContext?.type === ContextType.PROPERTY &&
      activeTab !== ContextOption.property
    ) {
      handleSetActiveTab(ContextOption.property);
    }
    if (
      selectedContext?.type === ContextType.CRO &&
      activeTab !== ContextOption.cro
    ) {
      handleSetActiveTab(ContextOption.cro);
    }
    if (
      selectedContext?.type === ContextType.PROFILE_CENTER &&
      activeTab !== ContextOption.profileCenter
    ) {
      handleSetActiveTab(ContextOption.profileCenter);
    }

    if (shouldActiveConfiguration) {
      handleSetActiveTab(ContextOption.configuration);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContext?.type, shouldActiveConfiguration]);

  const triggerIdWithoutHash = triggerId?.substring(1);

  const contextList = allContextLists.get(activeTab);

  const isBreadcrumbsUnitButton =
    triggerIdWithoutHash === breadcrumbsUnitItemId;

  const optionsArray =
    activeTab === ContextOption.configuration
      ? getContextList(contextList, selectedProperty, tabsOptions)
      : getContextList(contextList, selectedContext, tabsOptions);

  return (
    <ac-flex
      id="context-select-wrapper"
      class={isMenuV2EnabledCached ? '' : 'ac-spacing-right-s'}
      direction={FlexDirection.row}
      alignItems={AlignItems.center}
    >
      <ac-click-outside
        onClickOutsideCallback={isBreadcrumbsUnitButton ? undefined : closeList}
      >
        {!isContextSwitchHidden && (
          <ContextDropdown
            selectedContext={selectedContext}
            businessDate={date}
            dateFormat={dateFormat}
            propertyTime={time}
            color={color}
            isListVisible={isListVisible}
            onClick={openList}
            toShowOnlyIconInDropDown={
              currentButtonOption &&
              currentButtonOption.id === ContextOption.configuration
            }
            tooltipContent={t('MENU.CONTEXT_SWITCH.TOOLTIP')}
          />
        )}
        <GenericList
          list={allContextLists.get(activeTab)}
          isVisible={isListVisible}
          onSelect={handleSelect}
          onClose={closeList}
          triggerId={triggerId}
          activeTab={activeTab}
          distanceFromTarget={distanceFromTarget}
          selectedItemsIds={selectedUnits}
          optionsArray={optionsArray}
          selectedContext={selectedContext}
        >
          {showTabs && (
            <ContextHeader
              selectedContext={selectedContext}
              date={date}
              dateLabel={dateLabel}
              time={time}
              timeLabel={timeLabel}
              buttonSelectOptions={tabsOptions}
              setActiveTab={handleSetActiveTab}
              activeTab={activeTab}
              isContextSwitchHidden={isContextSwitchHidden}
            />
          )}
        </GenericList>
      </ac-click-outside>
    </ac-flex>
  );
};
