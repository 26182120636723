// Note: Cannot use arrow function when function is overloaded

import { createContext } from 'react';
import {
  RouterMutableStore,
  RouterStore,
} from 'frontend-container/components/Router/context/store';

import { useDefinedContext, useReactiveStore } from '@ac/react-infrastructure';

export const RouterContext = createContext<RouterMutableStore | undefined>(
  undefined
);

export const useRouterContext = <TField>(
  selector: (store: RouterStore) => TField
): TField => {
  const store = useDefinedContext(RouterContext);

  return useReactiveStore(store, selector);
};
