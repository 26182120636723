import { useTranslation } from 'react-i18next';
import { PropertySelectorReadOnly } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/PropertySelectorReadOnly';
import { isPropertySelectorReadOnlyVisible } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/visibility';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/utils/isMenuV2Enabled';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { useSelectContext } from 'frontend-container/components/Router/utils/useSelectContext';

import { ContextComponent } from '../Context';

import { useCroDateTime } from './useCroDateTime';

interface Props {
  isContextSwitchHidden?: boolean;
  triggerId?: string;
}

export const CroContext = ({
  isContextSwitchHidden,
  triggerId,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const selectedCro = useRouterContext((store) => store.selectedCro);
  const onSelect = useSelectContext();

  const isMenuV2Enabled = getIsMenuV2Enabled();

  return !selectedCro ? (
    <ac-loader-covering />
  ) : (
    <>
      {isPropertySelectorReadOnlyVisible() && !isMenuV2Enabled && (
        <PropertySelectorReadOnly />
      )}
      <ContextComponent
        selectedContext={selectedCro}
        onSelect={onSelect}
        useDateTime={useCroDateTime}
        dateLabel={t('MENU.CONTEXT.CRO.DATE')}
        timeLabel={t('MENU.CONTEXT.CRO.TIME')}
        isContextSwitchHidden={isContextSwitchHidden}
        triggerId={triggerId}
      />
    </>
  );
};
