import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  croLandingPagePathname,
  propertyLandingPagePathname,
} from 'frontend-container/components/LandingPage';
import { profileCenterLandingPagePathname } from 'frontend-container/components/LandingPage/location';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import {
  getConfigurationContexts,
  isConfigurationEnabled,
} from 'frontend-container/components/Menu/components/ConfigurationContext/service';
import { Context } from 'frontend-container/components/Menu/components/Context/index';
import { getCroContexts } from 'frontend-container/components/Menu/components/CroContext/service';
import { getProfileCentersContexts } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { getPropertyContexts } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/croModules/centralAvailabilityInquiry';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/croModules/reservationsLookup';
import { configurationMenu } from 'frontend-container/components/Menu/configuration/multiContextModules/configuration';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import { getCurrentContextOption } from 'frontend-container/components/Menu/utils/getCurrentContext';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { UserSelectedUnits } from 'frontend-container/components/Router/hooks/useSelectedUserUnits';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { isDefined } from '@ac/library-utils/dist/utils';
import { IconName } from '@ac/web-components';

export enum ContextOption {
  property = 'property',
  cro = 'cro',
  profileCenter = 'profile-center',
  configuration = 'configuration',
}

export type ButtonContextOption = {
  id: string;
  label: string;
  icon: IconName;
  onClick: () => void;
  getContext: (
    currentButtonOption?: ButtonContextOption
  ) => Context[] | ButtonContextOption | undefined;
  url: string;
  subMenuElements?: MenuElementItem[];
  unitName?: string;
};

export type ContextOptions = {
  [key in ContextOption]: {
    buttonOption: ButtonContextOption;
    guard: boolean;
  };
};

export type ContextSelectButtonOptions = {
  contextOptions: ContextOptions;
  buttonSelectOptions: ButtonContextOption[];
  currentButtonOption?: ButtonContextOption;
};

type UseContextMenuItems = {
  getContextMenuItems: (
    accessConfiguration?: AllAccessConfiguration
  ) => ContextSelectButtonOptions;
};

const getMenuElementItems = (
  menuElements: MenuElement[],
  useMenuElementLabel?: boolean
): MenuElementItem[] => {
  if (useMenuElementLabel) {
    return menuElements.flatMap((element) =>
      element.items.map((item) => ({
        ...item,
        translation: element.translation,
      }))
    );
  }

  return menuElements.flatMap((element) => element.items);
};

const getContextUnitCode = (
  context: ContextOption,
  selectedUnits: UserSelectedUnits
): string | undefined => {
  switch (context) {
    case ContextOption.cro: {
      return selectedUnits.selectedCro?.code;
    }
    case ContextOption.property: {
      return selectedUnits.selectedProperty?.code;
    }
    case ContextOption.profileCenter: {
      return selectedUnits.selectedProfileCenter?.code;
    }
    default: {
      return undefined;
    }
  }
};

export const useContextMenuItems = (): UseContextMenuItems => {
  const { t } = useTranslation();
  const selectedCro = useRouterContext((store) => store.selectedCro);
  const selectedProfileCenter = useRouterContext(
    (store) => store.selectedProfileCenter
  );
  const selectedProperty = useRouterContext((store) => store.selectedProperty);

  const selectedUnits = useMemo(
    () => ({
      selectedCro,
      selectedProfileCenter,
      selectedProperty,
    }),
    [selectedCro, selectedProfileCenter, selectedProperty]
  );

  const getContextMenuItems = useCallback(
    (
      accessConfiguration?: AllAccessConfiguration
    ): ContextSelectButtonOptions => {
      const croGuard = (): boolean => {
        return isDefined(selectedCro);
      };

      const currentContextOption = getCurrentContextOption();

      const croMenuSubItems = (): MenuElementItem[] =>
        accessConfiguration
          ? getMenuElementItems(
              getAllowedMenuItems(
                [centralAvailabilityInquiryMenu, reservationsLookupMenu],
                accessConfiguration
              ),
              true
            )
          : [];

      const configurationSubItemsMenu = (): MenuElementItem[] =>
        accessConfiguration
          ? getMenuElementItems(
              getAllowedMenuItems([configurationMenu], accessConfiguration)
            )
          : [];

      const propertyGuard = (): boolean => {
        return isDefined(selectedProperty);
      };

      const profileCenterGuard = (): boolean => {
        return isDefined(selectedProfileCenter);
      };

      const configurationGuard = (): boolean => {
        return isConfigurationEnabled();
      };

      const contextOptions: ContextOptions = {
        [ContextOption.property]: {
          buttonOption: {
            id: ContextOption.property,
            label: t('MENU.CONTEXT.SWITCH_TO_PROPERTY'),
            icon: IconName.propertyZone,
            url: propertyLandingPagePathname,
            unitName: getContextUnitCode(ContextOption.property, selectedUnits),
            getContext: (): Context[] => getPropertyContexts(),
            onClick: (): void => navigateToPage(propertyLandingPagePathname),
          },
          guard: propertyGuard(),
        },
        [ContextOption.profileCenter]: {
          buttonOption: {
            id: ContextOption.profileCenter,
            label: t('MENU.CONTEXT.SWITCH_TO_PROFILE_CENTER'),
            icon: IconName.profileDetails,
            unitName: getContextUnitCode(
              ContextOption.profileCenter,
              selectedUnits
            ),
            url: profileCenterLandingPagePathname,
            getContext: (): Context[] => getProfileCentersContexts(),
            onClick: (): void =>
              navigateToPage(profileCenterLandingPagePathname),
          },
          guard: profileCenterGuard(),
        },
        [ContextOption.cro]: {
          buttonOption: {
            id: ContextOption.cro,
            label: t('MENU.CONTEXT.SWITCH_TO_CRO'),
            icon: IconName.cro,
            url: croLandingPagePathname,
            unitName: getContextUnitCode(ContextOption.cro, selectedUnits),
            onClick: (): void => navigateToPage(croLandingPagePathname),
            getContext: (): Context[] => getCroContexts(),
            subMenuElements: croMenuSubItems(),
          },
          guard: croGuard(),
        },
        [ContextOption.configuration]: {
          buttonOption: {
            id: ContextOption.configuration,
            label: t('MENU.CONTEXT.SWITCH_TO_CONFIGURATION'),
            icon: IconName.settings,
            url: acConfig.newFrontendUrls.configurationv2,
            getContext: (
              currentButtonOption?: ButtonContextOption
            ): Context[] | ButtonContextOption | undefined =>
              getConfigurationContexts(currentButtonOption),
            onClick: (): void =>
              navigateToPage(acConfig.newFrontendUrls.configurationv2),
            subMenuElements: configurationSubItemsMenu(),
          },
          guard: configurationGuard() || configurationSubItemsMenu().length > 0,
        },
      };

      const buttonSelectOptions = Object.values(contextOptions).reduce<
        ButtonContextOption[]
      >((acc, curr) => {
        if (curr.guard) {
          return [...acc, { ...curr.buttonOption }];
        }

        return acc;
      }, []);

      return {
        contextOptions,
        buttonSelectOptions,
        currentButtonOption:
          currentContextOption &&
          contextOptions[currentContextOption].buttonOption,
      };
    },
    [selectedCro, selectedProfileCenter, selectedProperty, selectedUnits, t]
  );

  return {
    getContextMenuItems,
  };
};
