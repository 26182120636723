import { FC, ReactNode, useEffect, useState } from 'react';
import { breadcrumbsUnitItemId } from 'frontend-container/components/Menu/components/Breadcrumbs/Breadcrumbs';
import { Context } from 'frontend-container/components/Menu/components/Context/context';
import { TabOption } from 'frontend-container/components/Menu/components/Context/mapListsToOptions';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import {
  handleOnChange,
  isContextList,
} from 'frontend-container/components/Menu/utils/listOnChangeHandler';

import {
  AcSelectOption,
  Placement,
  SelectableListSlot,
  TargetValueObject,
} from '@ac/web-components';

interface Props {
  list: Context[] | ButtonContextOption | undefined;
  selectedContext: Context | undefined;
  isVisible?: boolean;
  children: ReactNode;
  onSelect: (
    selected: AcSelectOption[] | Context | object[] | undefined
  ) => void;
  onClose: () => void;
  buttonSelectOptions?: ButtonContextOption[];
  searchableListLength?: number;
  triggerId?: string;
  activeTab?: string | undefined;
  distanceFromTarget?: number;
  selectedItemsIds: string[];
  optionsArray: TabOption[];
}

export const GenericList: FC<Props> = ({
  list,
  isVisible,
  optionsArray,
  children,
  onSelect,
  onClose,
  searchableListLength = 7,
  triggerId,
  activeTab,
  distanceFromTarget = 0,
  selectedItemsIds,
}) => {
  const onClick = (context: Context | undefined): void => {
    onSelect(context);
  };

  const [searchValue, setSearchValue] = useState<string | undefined>();

  useEffect(() => {
    if (activeTab) {
      setSearchValue(undefined);
    }
  }, [activeTab]);

  const contextType = isContextList(list);

  const listLength = contextType
    ? list.length
    : (list?.subMenuElements?.length ?? 0);
  const onChange = contextType ? handleOnChange(list, onClick) : undefined;

  const searchable = listLength > searchableListLength;

  const contextListProps = {
    searchable,
    optionSelectedField: 'value',
    value: selectedItemsIds,
    onChangeCallback: onChange,
  };

  const nonContextListProps = {
    searchable: false,
    optionSelectedField: 'id',
    value: [`${Math.random()}`], // new value will disable selected item
    selectedItemsSection: false,
    onChangeCallback: onSelect,
  };

  const currentListProps = contextType ? contextListProps : nonContextListProps;

  const target = triggerId ?? '#context-list-wrapper';

  return (
    <div id="context-list-wrapper">
      <ac-selectable-list
        minOptionsWidth={{ listContainer: 400, optionsWrapper: 400 }}
        attachTo={target}
        boundaryContainer="body"
        autoFilter
        isVisible={isVisible}
        placement={Placement.bottomEnd}
        target={target}
        targetValue={TargetValueObject.mainMenu}
        optionNameField="name"
        optionValueField="value"
        dropdownClass="selectable-list-dropdown-wrapper"
        onCloseCallback={onClose}
        optionsArray={optionsArray}
        searchValue={searchValue}
        onInputCallback={(value): void => setSearchValue(value)}
        offset={{ distanceFromTarget, shiftFromTheMiddle: 0 }}
        {...currentListProps}
      >
        <div
          slot={SelectableListSlot.listHeader}
          className={
            triggerId === `#${breadcrumbsUnitItemId}`
              ? 'list-header-gray'
              : 'list-header'
          }
        >
          {children}
        </div>
      </ac-selectable-list>
    </div>
  );
};
