import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import {
  getCroContexts,
  isCroEnabled,
} from 'frontend-container/components/Menu/components/CroContext/service';
import {
  getProfileCentersContexts,
  isCampaignManagementEnabled,
  isProfileCentersEnabled,
} from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/croModules/centralAvailabilityInquiry';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/croModules/reservationsLookup';
import { campaignManagementMenu } from 'frontend-container/components/Menu/configuration/profileCenterModules/campaignManagement';
import { profileCenterMenu } from 'frontend-container/components/Menu/configuration/profileCenterModules/profileCenters';
import { getCustomMenuElementConfig } from 'frontend-container/components/Menu/configuration/utils/getCustomMenuElementConfig';
import { useContextsContext } from 'frontend-container/components/Menu/store/context';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export type RightMenuItem = {
  selectedContextCode?: string;
  menuElements?: MenuElement[];
  withoutHeader?: boolean;
  isSeparator?: boolean;
};

export const useRightColumnConfig = (
  allAccessConfiguration: AllAccessConfiguration | undefined,
  selectedCro: Context | undefined,
  selectedProfileCenter: Context | undefined
): RightMenuItem[] => {
  const { t } = useTranslation();

  const { allContextLists } = useContextsContext();

  const configuration = allContextLists.get('configuration') as
    | ButtonContextOption
    | undefined;

  const configurationMainItems = configuration?.subMenuElements?.filter(
    (configurationSubElement) =>
      configurationSubElement.link.startsWith(
        `${acConfig.newFrontendUrls.configurationv2}/customers`
      )
  );

  const configurationSecondaryItems = configuration?.subMenuElements?.filter(
    (configurationSubElement) =>
      !configurationSubElement.link.startsWith(
        `${acConfig.newFrontendUrls.configurationv2}/customers`
      )
  );

  const allowedProfileCenter = useMemo(() => {
    if (allAccessConfiguration) {
      const menuElements = [profileCenterMenu, campaignManagementMenu];

      return getAllowedMenuItems(menuElements, allAccessConfiguration);
    }

    return [];
  }, [allAccessConfiguration]);

  const croContexts = useMemo(() => getCroContexts(), []);
  const profileCentersContexts = useMemo(() => getProfileCentersContexts(), []);
  const centralAvailabilityInquiryMenuItem = useMemo(() => {
    return {
      ...centralAvailabilityInquiryMenu.items[0],
      translation: t(`${centralAvailabilityInquiryMenu.items[0].translation}`),
    };
  }, [t]);
  const reservationsLookupMenuItem = useMemo(() => {
    return {
      ...reservationsLookupMenu.items[0],
      translation: t(`${reservationsLookupMenu.items[0].translation}`),
    };
  }, [t]);

  return useMemo(() => {
    const menuElements: RightMenuItem[] = [];

    if (
      isProfileCentersEnabled() &&
      !!allowedProfileCenter?.length &&
      !!profileCentersContexts?.length
    ) {
      menuElements.push({
        selectedContextCode: selectedProfileCenter?.code,
        menuElements: [
          {
            ...allowedProfileCenter[0],
            icon: IconName.perPax,
            translation: t(allowedProfileCenter[0].translation),
            items: isCampaignManagementEnabled()
              ? [
                  ...allowedProfileCenter[0].items.map((item) => ({
                    ...item,
                    translation: t(item.translation),
                  })),
                  ...allowedProfileCenter[1].items.map((item) => ({
                    ...item,
                    translation: t(item.translation),
                  })),
                ]
              : [
                  ...allowedProfileCenter[0].items.map((item) => ({
                    ...item,
                    translation: t(item.translation),
                  })),
                ],
          },
        ],
      });
    }

    if (isCroEnabled() && !!croContexts?.length) {
      menuElements.push({
        selectedContextCode: selectedCro?.code,
        menuElements: [
          {
            translation: t('MENU.CRO.TITLE'),
            icon: IconName.cro,
            id: 'menu-cro',
            items: [
              centralAvailabilityInquiryMenuItem,
              reservationsLookupMenuItem,
            ],
          },
        ],
      });
    }

    if (configuration && !!configurationMainItems?.length) {
      menuElements.push({
        menuElements: [
          {
            ...getCustomMenuElementConfig(),
            translation: configuration.label,
            id: `${configuration.id}-main-items`,
            icon: IconName.settings,
            items: configurationMainItems ?? [],
          },
        ],
      });
    }

    if (
      !!configurationMainItems?.length &&
      !!configurationSecondaryItems?.length
    ) {
      menuElements.push({
        isSeparator: true,
      });
    }

    if (
      configuration &&
      !!configurationMainItems?.length &&
      !!configurationSecondaryItems?.length
    ) {
      menuElements.push({
        withoutHeader: true,
        menuElements: [
          {
            ...getCustomMenuElementConfig(),
            translation: configuration.label,
            id: `${configuration.id}-secondary-items`,
            icon: IconName.settings,
            items: configurationSecondaryItems ?? [],
          },
        ],
      });
    }

    return menuElements;
  }, [
    centralAvailabilityInquiryMenuItem,
    reservationsLookupMenuItem,
    allowedProfileCenter,
    profileCentersContexts,
    selectedProfileCenter,
    selectedCro,
    croContexts,
    configuration,
    configurationMainItems,
    configurationSecondaryItems,
    t,
  ]);
};
