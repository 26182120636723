import { isProfileCentersContextVisible } from 'frontend-container/components/Menu/components/ProfileCentersContext/isProfileCentersContextVisible';
import { Workspace } from 'frontend-container/components/Menu/types';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { isDistributionChannelManagerModule } from 'frontend-container/components/Menu/utils/modules/distributionChannelManager';
import { isPropertyDependentInventoryModule } from 'frontend-container/components/Menu/utils/modules/inventory';
import { isItineraryModule } from 'frontend-container/components/Menu/utils/modules/itinerary';
import { isNotificationsDashboard } from 'frontend-container/components/Menu/utils/modules/notifications';
import { getSelectedMainMenuItem } from 'frontend-container/components/Router/utils/getSelectedMainMenuItem';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { isPropertyBusinessContextData } from '@ac/library-api';

const isPropertyBusinessContextOnErrorPage = (): boolean => {
  return (
    isErrorPage() && isPropertyBusinessContextData(getBusinessContextData())
  );
};

export const isPropertyContextVisible = (): boolean => {
  if (isProfileCentersContextVisible()) {
    return false;
  }

  const pathUtilFunctions = [
    isNotificationsDashboard,
    isPropertyDependentInventoryModule,
    isPropertyBusinessContextOnErrorPage,
    isItineraryModule,
    isDistributionChannelManagerModule,
  ];

  const { element } = getSelectedMainMenuItem();

  const isModuleInPropertyWorkspace =
    element?.workspaceType() === Workspace.PROPERTY;

  return (
    isModuleInPropertyWorkspace || pathUtilFunctions.some((func) => func())
  );
};
