import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import {
  MenuElement,
  Workspace,
} from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import {
  FeatureToggleName,
  HousekeepingCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const housekeepingPermissions = propertyPermissionKeys.housekeeping;

export const HOUSEKEEPING_DASHBOARD_LINK = `${acConfig.newFrontendUrls.housekeeping}/dashboard`;

const HOUSEKEEPING_MENU_ID = 'menu-housekeeping';

export const housekeepingMenu: MenuElement = {
  workspaceType: () => Workspace.PROPERTY,
  translation: 'MENU.HOUSEKEEPING.TITLE',
  icon: IconName.housekeeping,
  id: HOUSEKEEPING_MENU_ID,
  settings: [
    {
      key: HousekeepingCustomerSettingsKeys.UseHousekeepingFunctionality,
      source: AccessSource.Property,
    },
  ],
  items: [
    {
      link: HOUSEKEEPING_DASHBOARD_LINK,
      aliases: [acConfig.newFrontendUrls.housekeeping],
      code: 'HousekeepingDashboard',
      id: `${HOUSEKEEPING_MENU_ID}-dashboard`,
      translation: 'MENU.HOUSEKEEPING.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.rooms.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}K`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}K`,
      keyCode: 75,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/attendant-assignments`,
      translation: 'MENU.HOUSEKEEPING.ITEMS.ATTENDANT_ASSIGNMENT',
      id: `${HOUSEKEEPING_MENU_ID}-attendant-assignment`,
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.attendantAssignment.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}D`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}D`,
      keyCode: 68,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/attendant-assignments-v2`,
      translation: 'MENU.HOUSEKEEPING.ITEMS.ATTENDANT_ASSIGNMENT_V2',
      id: `${HOUSEKEEPING_MENU_ID}-attendant-assignment-v2`,
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.attendantAssignment.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      featureToggles: [
        {
          key: FeatureToggleName.HousekeepingShifts,
          source: AccessSource.Property,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/maintenance-dashboard`,
      translation: 'MENU.HOUSEKEEPING.ITEMS.DASHBOARD_MAINTENANCE',
      id: `${HOUSEKEEPING_MENU_ID}-dashboard-maintenance`,
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.maintenance.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}O`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}O`,
      keyCode: 79,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/room-history`,
      translation: 'MENU.HOUSEKEEPING.ITEMS.ROOM_HISTORY',
      id: `${HOUSEKEEPING_MENU_ID}-room-history`,
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.roomsHistory.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}Y`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}Y`,
      keyCode: 89,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/printed-sheets-history`,
      translation: 'MENU.HOUSEKEEPING.ITEMS.PRINTED_SHEETS_HISTORY',
      id: `${HOUSEKEEPING_MENU_ID}-printed-sheets-history`,
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.attendantAssignment.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};
