import { useCallback, useEffect, useMemo } from 'react';
import { pathname403 } from 'frontend-container/components/Errors/Error403';
import { getAllAccessConfiguration } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import { getIsMenuComponentAllowed } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import {
  getAllowedMainApplicationMenu,
  getFullMenu,
} from 'frontend-container/components/Menu/configuration';
import { findSelectedMenuElement } from 'frontend-container/components/Menu/menuItems';
import { isBusinessContextDataGoingToChange } from 'frontend-container/components/Menu/service';
import { getElementsWithSeparateExternalLinks } from 'frontend-container/components/Menu/utils/getElementsWithSeparateExternalLinks';
import { isModuleWithoutMainMenuEntry } from 'frontend-container/components/Menu/utils/isModuleWithoutMainMenuEntry';
import { isReadOnlyRequired } from 'frontend-container/components/ReadOnlyMode/isReadOnlyRequired';
import {
  getReadOnlyByUser,
  setReadOnlyMode,
} from 'frontend-container/components/ReadOnlyMode/setReadOnlyMode';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { useRefreshContextForRouter } from 'frontend-container/components/Router/hooks/useRefreshRouterContext';
import { emberPathNameAppNameMap } from 'frontend-container/config/emberPathnameAppNameMap';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { replaceWithPage } from 'frontend-container/shared/navigation/replaceWithPage';
import { getAppScopeFromPathname } from 'frontend-container/utils/getAppScopeFromPathname';
import { isEmberAppByAppName } from 'frontend-container/utils/isEmberApp';
import { unloadApplication } from 'single-spa';

import { globalBusinessContextUpdatedEventCreator } from '@ac/library-api';

let usedApp: string | undefined;

const unloadEmberApplication = async (): Promise<void> => {
  const scope = getAppScopeFromPathname();
  if (
    !!usedApp &&
    emberPathNameAppNameMap[scope] !== usedApp &&
    isEmberAppByAppName(usedApp)
  ) {
    await unloadApplication(usedApp ?? '');
  }
  usedApp = emberPathNameAppNameMap[scope];
};

export const Router = (): null => {
  const refreshContext = useRefreshContextForRouter();
  const context = useRouterContext((store) => store);

  const fullMenuConfiguration = useMemo(() => getFullMenu(), []);
  const redirectWithoutMenuItemAccess = useCallback(
    (accessConfiguration: AllAccessConfiguration): boolean => {
      const { element: selectedMenuElement, item: selectedMenuItem } =
        findSelectedMenuElement(fullMenuConfiguration);

      const isMenuItemNotAllowed =
        selectedMenuItem &&
        !getIsMenuComponentAllowed(selectedMenuItem, accessConfiguration);

      const isMenuElementNotAllowed =
        selectedMenuElement &&
        !isModuleWithoutMainMenuEntry() &&
        !getIsMenuComponentAllowed(selectedMenuElement, accessConfiguration);

      if (isMenuItemNotAllowed || isMenuElementNotAllowed) {
        replaceWithPage(pathname403);

        return true;
      }

      return false;
    },
    [fullMenuConfiguration]
  );

  const updateRoute = useCallback(
    async (event?: Event): Promise<void> => {
      const isPopstateEvent = event && event.type === 'popstate';
      if (isPopstateEvent) {
        const newPathname = location.pathname;
        if (newPathname === context.locationRef) {
          return;
        }
        context.setLocation(newPathname);
      }

      await unloadEmberApplication();
      // Only modules which are editable in global region care for readOnly mode.
      // Each time we enter into such module, if we are not in global region, we should be in readOnly mode.
      // That's why we active readOnly mode for modules other then the ones editable in global region.
      if (isReadOnlyRequired()) {
        const newValue = getReadOnlyByUser() ?? true;
        setReadOnlyMode(newValue);
      }

      if (isBusinessContextDataGoingToChange()) {
        return;
      }

      const accessConfiguration = getAllAccessConfiguration();

      context.setAllAccessConfiguration(accessConfiguration);

      const isRedirected = redirectWithoutMenuItemAccess(accessConfiguration);

      if (isRedirected) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      context.locationRef,
      context.setLocation,
      context.setAllAccessConfiguration,
      redirectWithoutMenuItemAccess,
    ]
  );

  useEffect(() => {
    const allowedItems = context.allAccessConfiguration
      ? getAllowedMainApplicationMenu(context.allAccessConfiguration)
      : [];
    context.setUserMenuElements(
      getElementsWithSeparateExternalLinks(allowedItems)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.allAccessConfiguration, context.setUserMenuElements]);

  useEffect(() => {
    const eventBus = getCurrentGlobalEventBus();

    window.addEventListener('popstate', updateRoute);
    const unsubscribeBusinessContextUpdatedEvent = eventBus.subscribe(
      globalBusinessContextUpdatedEventCreator,
      () => updateRoute()
    );

    return (): void => {
      window.removeEventListener('popstate', updateRoute);
      unsubscribeBusinessContextUpdatedEvent();
    };
  }, [updateRoute]);

  useEffect(() => {
    void updateRoute();
  }, [updateRoute]);

  useEffect(() => {
    refreshContext();
  }, [refreshContext]);

  return null;
};
